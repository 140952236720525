import React from "react";

const cars = ({ fill = "#00A68B", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_273_2690)">
        <path
          id="Vector"
          d="M17.65 33.1334C15.9781 32.8973 14.3531 32.4025 12.8333 31.6667L5 33.3334L7.16667 26.8334C3.29333 21.105 4.79 13.7134 10.6667 9.54336C16.5433 5.37503 24.9833 5.71669 30.4083 10.3434C33.0167 12.5684 34.54 15.4517 34.925 18.445"
          stroke={fill}
          strokeWidth="3.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M29.9999 36.6666L35.5832 31.1933C35.9256 30.8611 36.1979 30.4636 36.3842 30.0243C36.5704 29.5851 36.6668 29.113 36.6676 28.6359C36.6683 28.1588 36.5735 27.6864 36.3887 27.2466C36.2039 26.8067 35.9328 26.4083 35.5915 26.075C34.8951 25.3939 33.9602 25.0117 32.9861 25.0098C32.0119 25.0079 31.0756 25.3866 30.3765 26.065L30.0032 26.4316L29.6315 26.065C28.9352 25.3844 28.0006 25.0025 27.0269 25.0006C26.0532 24.9987 25.1172 25.3771 24.4182 26.055C24.0757 26.3871 23.8032 26.7845 23.6168 27.2237C23.4305 27.6629 23.3339 28.1349 23.333 28.612C23.3321 29.0891 23.4267 29.5616 23.6114 30.0015C23.7961 30.4414 24.067 30.8398 24.4082 31.1733L29.9999 36.6666Z"
          stroke={fill}
          strokeWidth="3.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_273_2690">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default cars;
